<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="huyThanhToan(true)"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">THANH TOÁN BÁN VÉ</div>
                </slot>
                <slot name="after">
                    <div class="shrink" style="opacity: 0">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>
        <ion-content :scroll-events="false">
            <div class="column container-md py-0" style="height: unset">
                <div class="text-xs-center py-3 font-bold">
                    Hướng dẫn hành khách quét camera vào mã QR
                </div>
                <div class="row justify-center pt-2">
                    <div>
                        <div
                            class="pa-2 row justify-center align-center shadow"
                            style="
                                border-radius: 7px;
                                width: 270px;
                                height: 270px;
                                position: relative;
                            "
                        >
                            <div
                                v-if="hetThoiGianThanhToan"
                                style="
                                    position: absolute;
                                    top: 40%;
                                    left: 23%;
                                    font-size: 16px;
                                    z-index: 8;
                                "
                            >
                                <div class="column justify-center">
                                    Hết thời gian thanh toán
                                    <ion-button
                                        color="primary"
                                        style="margin: 0 auto"
                                        class="mt-2"
                                        expand="block"
                                        @click="thucHienThanhToan()"
                                    >
                                        Tạo lại mã
                                    </ion-button>
                                </div>
                            </div>
                            <div
                                :style="
                                    hetThoiGianThanhToan ? 'opacity:0.1' : ''
                                "
                                class="full-width full-height"
                            >
                                <QrCodeVue
                                    v-if="qrCode"
                                    :data="qrCode"
                                    style="background-color: white"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style="text-align: center"
                    :class="`pt-3 pb-2 ${color}--text font-14`"
                >
                    <span class="font-weight-regular"
                        >Thời gian thanh toán :</span
                    ><span class="font-weight-regular pl-4">{{
                        time_session
                    }}</span>
                </div>
                <div
                    class="row justify-space-around font-16 font-bold"
                    style="width: 80%; margin: 0px auto !important"
                    py-3
                >
                    <div class="pr-2">Số tiền thanh toán :</div>
                    <div>{{ $MoneyFormat(tongTienThanhToan) }}đ</div>
                </div>
                <div class="py-4">
                    <div class="container-md boundary">
                        <div class="font-14 font-bold text-xs-center">
                            Thông tin người nhận
                        </div>
                        <div class="font-12">
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Người nhận:
                                </div>
                                <div class="xs7">
                                    {{ NguoiNhan }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Số tài khoản nhận:
                                </div>
                                <div class="xs7">
                                    {{ SoTaiKhoan }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Số điện thoại:
                                </div>
                                <div class="xs7">{{ SoDienThoai }}</div>
                            </div>
                            <div class="row pt-2">
                                <div
                                    class="font-bold xs5"
                                    style="min-width: 58px"
                                >
                                    Ngân hàng:
                                </div>
                                <div class="xs7">{{ NganHang }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { DxButton } from "devextreme-vue";
import {
    IonContent,
    IonPage,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
import { mapState } from "vuex";
import ThanhTieuDeVue from "../../../components/_Common/ThanhTieuDe.vue";
import QrCodeVue from "../../../components/QrCode.vue";

export default {
    components: {
        ThanhTieuDeVue,
        QrCodeVue,
        DxButton,
        IonContent,
        IonPage,
    },
    data() {
        return {
            hetThoiGianThanhToan: false,
            qrCode: "abcd",
            NguoiNhan: null,
            SoTaiKhoan: null,
            SoDienThoai: null,
            NganHang: null,
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
        tongTienThanhToan() {
            return 0;
        },
    },
    methods: {
        huyThanhToan() {},
        thucHienThanhToan() {},
    },
    created() {
        onIonViewWillEnter(async () => {});
        onIonViewWillLeave(async () => {});
    },
};
</script>
<style lang="scss" scoped>
</style>