<template>
    <div class="qrcodes row align-center justify-center">
        <div class="che-qrcode" v-if="!data"></div>
        <img
            :src="`https://image-charts.com/chart?cht=qr&chs=${size}x${size}&choe=UTF-8&chld=L&chl=${data}`"
            style="
                width: calc(100% + 16px) !important;
                height: calc(100% + 16px) !important;
                max-width: unset;
            "
            alt=""
        />
        <!-- <canvas :id="`qrcode${id || 1}`"></canvas> -->
    </div>
</template>

<script>
var QRCode = require("qrcode");
export default {
    props: {
        id: { type: Number, default: 1 },
        data: { type: String, default: "" },
        size: { type: String, default: "288" },
    },
    data() {
        return {
            qrcode: null,
        };
    },
    mounted() {
        // this.getQrCode();
    },
    methods: {
        getQrCode() {
            this.qrcode = null;
            QRCode.toCanvas(
                document.getElementById("qrcode" + (this.id || 1)),
                this.data || "sonphat.net",
                { errorCorrectionLevel: "L", margin: 2 },
                function (error) {
                    if (error) console.error(error);
                    //success
                }
            );
            return;
            if (this.qrcode) {
                this.qrcode.clear();
                this.qrcode.makeCode(this.data);
                this.qrcode._el.title = "";
            }
        },
    },
    beforeUnmount() {
        if (window.timer_qr) {
            clearInterval(window.timer_qr);
        }
    },
    watch: {
        data: {
            handler: function () {
                setTimeout(() => {
                    this.getQrCode();
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style scoped>
.close-style {
    position: absolute;
    top: 0;
    right: 0;
}
.qrcodes {
    position: relative;
    /* max-width: 180px;
    max-height: 180px; */
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.che-qrcode {
    position: absolute;
    background-color: #ffffff;
    left: 2px;
    top: 2px;
    width: 98%;
    height: 98%;
}
</style>